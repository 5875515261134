import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const InlineNestedAlert = makeShortcode("InlineNestedAlert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" api="extension-version" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@NonNull
public static String extensionVersion()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final String extensionVersion = Assurance.extensionVersion();
`}</code></pre>
    <h4 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val extensionVersion: String = Assurance.extensionVersion()
`}</code></pre>
    <Variant platform="ios" api="extension-version" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`AEPAssurance.extensionVersion()
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (nonnull NSString*) extensionVersion;
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[AEPAssurance extensionVersion];
`}</code></pre>
    {/*- <Variant platform="react-native" api="extension-version" repeat="3"/>
     #### JavaScript
     **Example**
     ```objectivec
     AEPAssurance.extensionVersion().then(version => console.log("AdobeExperienceSDK: AEP Assurance version: " + version));
     ```
     <Variant platform="flutter" api="extension-version" repeat="5"/>
     #### Dart
     **Syntax**
     ```dart
     static Future<String> get extensionVersion async
     ```
     **Example**
     ```dart
     assuranceVersion = await FlutterAssurance.extensionVersion;
     ``` -*/}
    <Variant platform="android" api="start-session" repeat="9" mdxType="Variant" />
    <InlineNestedAlert variant="info" header="false" iconPosition="left" mdxType="InlineNestedAlert">
      <p>{`This API is optional for Android. Deep linking is the best way to connect to an Assurance session when using the Android SDK. Assurance SDK on Android is already setup to handle incoming intents to your app. You can `}<a parentName="p" {...{
          "href": "https://developer.android.com/training/app-links/deep-linking"
        }}>{`add an intent filter for incoming links in your app`}</a>{` to complete the deep link configuration.`}</p>
    </InlineNestedAlert>
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void startSession(@NonNull final String url)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`final String url = "<assurance_session_url>";
Assurance.startSession(url);
`}</code></pre>
    <h4 {...{
      "id": "kotlin-1"
    }}>{`Kotlin`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`val url: String = "<assurance_session_url>"
Assurance.startSession(url)
`}</code></pre>
    <Variant platform="ios" api="start-session" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func application(_ app: UIApplication, open url: URL, options: [UIApplication.OpenURLOptionsKey : Any] = [:]) -> Bool {
    do {
        AEPAssurance.startSession(url)
        return false
    }
}
`}</code></pre>
    <p>{`For SceneDelegate based applications`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`    func scene(_ scene: UIScene, openURLContexts URLContexts: Set<UIOpenURLContext>) {
        AEPAssurance.startSession(URLContexts.first!.url)
    }
`}</code></pre>
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (void) startSession: (NSURL* _Nonnull) url;
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (BOOL)application:(UIApplication *)app openURL:(nonnull NSURL *)url options:(nonnull NSDictionary<UIApplicationOpenURLOptionsKey,id> *)options {
    [AEPAssurance startSession:url];
    return false;
}
`}</code></pre>
    {/*- <Variant platform="react-native" api="start-session" repeat="3"/>
     #### JavaScript
     **Example**
     ```javascript
     AEPAssurance.startSession("your-assurance-session-url");
     ```
     <Variant platform="flutter" api="start-session" repeat="5"/>
     #### Dart
     **Syntax**
     ```dart
     static Future<void> startSession(String url);
     ```
     **Example**
     ```dart
     FlutterAssurance.startSession(url);
     ``` -*/}
    <Variant platform="android" api="register-extension" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void registerExtension()
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Assurance.registerExtension();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      