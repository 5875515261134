import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
import Alerts from '/src/pages/resources/alerts.md';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Variant platform="android" task="add" repeat="11" mdxType="Variant" />
    <ol>
      <li parentName="ol">{`Add the Media extension and its dependencies to your project using the app's Gradle file.`}</li>
    </ol>
    <h4 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`implementation(platform("com.adobe.marketing.mobile:sdk-bom:3.+"))
implementation("com.adobe.marketing.mobile:core")
implementation("com.adobe.marketing.mobile:identity")
implementation("com.adobe.marketing.mobile:analytics")
implementation("com.adobe.marketing.mobile:media")
`}</code></pre>
    <h4 {...{
      "id": "groovy"
    }}>{`Groovy`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`implementation platform('com.adobe.marketing.mobile:sdk-bom:3.+')
implementation 'com.adobe.marketing.mobile:core'
implementation 'com.adobe.marketing.mobile:identity'
implementation 'com.adobe.marketing.mobile:analytics'
implementation 'com.adobe.marketing.mobile:media'
`}</code></pre>
    <Alerts query="platform=android-gradle&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Import the libraries in your application's main activity.`}</li>
    </ol>
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.MobileCore;
import com.adobe.marketing.mobile.Identity;
import com.adobe.marketing.mobile.Analytics;
import com.adobe.marketing.mobile.Media;
`}</code></pre>
    <h4 {...{
      "id": "kotlin-1"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`import com.adobe.marketing.mobile.MobileCore
import com.adobe.marketing.mobile.Identity
import com.adobe.marketing.mobile.Analytics
import com.adobe.marketing.mobile.Media
`}</code></pre>
    <Variant platform="ios" task="add" repeat="7" mdxType="Variant" />
    <ol>
      <li parentName="ol">{`To add the Media library and its dependencies to your project, add the following pods to your `}<inlineCode parentName="li">{`Podfile`}</inlineCode>{`:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`pod 'AEPCore', '~> 5.0'
pod 'AEPAnalytics', '~> 5.0'
pod 'AEPMedia', '~> 5.0'
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`In Xcode project, import the Media extension:`}</li>
    </ol>
    <p><strong parentName="p">{`Swift`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import AEPMedia
import AEPCore
import AEPIdentity
import AEPAnalytics
`}</code></pre>
    <p><strong parentName="p">{`Objective-C`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`@import AEPCore;
@import AEPMedia;
@import AEPAnalytics;
@import AEPIdentity;
`}</code></pre>
    <Variant platform="android" task="register" repeat="5" mdxType="Variant" />
    <p>{`To register Media with Mobile Core, import the Media library and register it:`}</p>
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MainApp extends Application {
     private final String ENVIRONMENT_FILE_ID = "YOUR_APP_ENVIRONMENT_ID";

     @Override
     public void onCreate() {
         super.onCreate();

         MobileCore.setApplication(this);
         MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID);

         List<Class<? extends Extension>> extensions = Arrays.asList(
                 Media.EXTENSION, Analytics.EXTENSION, Identity.EXTENSION);
         MobileCore.registerExtensions(extensions, o -> {
             Log.d(LOG_TAG, "AEP Mobile SDK is initialized");
         });
     }
 }
`}</code></pre>
    <h4 {...{
      "id": "kotlin-2"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`class MyApp : Application() {
    val ENVIRONMENT_FILE_ID = "YOUR_APP_ENVIRONMENT_ID"

    override fun onCreate() {
        super.onCreate()
        MobileCore.setApplication(this)
        MobileCore.configureWithAppID(ENVIRONMENT_FILE_ID)

        val extensions = listOf(Media.EXTENSION, Analytics.EXTENSION, Identity.EXTENSION)
        MobileCore.registerExtensions(extensions) {
            Log.d(LOG_TAG, "AEP Mobile SDK is initialized")
        }
    }
}
`}</code></pre>
    <Variant platform="ios" task="register" repeat="6" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p>{`In your app's `}<inlineCode parentName="p">{`_:didFinishLaunchingWithOptions`}</inlineCode>{` function, register the Audience Manager extension with the Mobile Core:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
   MobileCore.registerExtensions([Media.self, Analytics.self, Identity.self], {
   MobileCore.configureWith(appId: "yourAppId")
 })
 ...
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <p>{`In your app's `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions`}</inlineCode>{`, register Media with Mobile Core:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
    [AEPMobileCore registerExtensions:@[AEPMobileMedia.class, AEPMobileAnalytics.class, AEPMobileIdentity.class] completion:^{
    [AEPMobileCore configureWithAppId: @"yourAppId"];
  }];
  ...
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      